<template>
	<div>
		<s-crud
			title="Etiquetas por empacadora"
			:config="config"
			add
			@save="save($event)"
			:groupBy="'Presentation'"
		>
			<template v-slot="props">
				<v-container>
					<v-row>
						<v-col>
							<s-toolbar-person
								v-model="props.item.PrsDocumentNumber"
								@returnPerson="hadReturnPerson($event, props.item)"
							/>
						</v-col>
						<v-col>
							<s-text
								label="Empacadora"
								v-model="baler.NtpFullName"
								readonly
							></s-text>
						</v-col>
						<v-col>
							<s-select-definition
								label="Area"
								:def="1281"
								:dgr="39"
								v-model="TypeArea"
								@input="getPresentations($event)"
							/>
						</v-col>
						<v-col>
							<s-select
								label="Presentación"
								:items="itemsPresentation"
								item-text="FullPresentation"
								item-value="TrpID"
								v-model="props.item.TrpID"
							></s-select>
						</v-col>
						<v-col>
							<s-text
								number
								label="Cantidad"
								v-model="props.item.TbrQuantityPrint"
							></s-text>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</s-crud>

		<v-row justify="center" style="display: grid;">
			<v-col cols="12">
				<qr-code text="carlos" error-level="H"> </qr-code>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import _TagBalerService from "@/services/FreshProduction/TagBalerService";
	import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
	import _sPrfTagPresentationSupervisorService from "@/services/FreshProduction/PrfTagPresentationSupervisorService";
	import qrCode from "vue-qr-generator";

	export default {
		components: {
			sToolbarPerson,
			qrCode
		},

		data() {
			return {
				config: {
					service : _TagBalerService,
					model: {
						TbrID: "ID",
					},
					headers : [
						{text: 'ID', value: "TbrID"},
						{text: 'Empacador', value: "NtpFullName"},
						{text: 'Cantidad', value: "TbrQuantityPrint"},
						{text: 'Inicio', value: "TbrNumberBegin"},
						{text: 'Fin', value: "TbrNumberEnd"},
						{text: 'Presentación', value: "Presentation"},
					],
				},
				baler: {},
				itemsPresentation: [],
				TypeArea: 0,
			}
		},

		methods: {
			hadReturnPerson(value, item) {
				if(value != undefined || value != null)
				{
					this.baler = value;
					item.PrsID = this.baler.PrsID;
				}
			},

			getPresentations(item) {
				_sPrfTagPresentationSupervisorService
				.list(this.TypeArea, this.$fun.getUserID())
				.then((resp) => {
					if (resp.status == 200) {
						this.itemsPresentation = resp.data;
						this.itemsPresentation.forEach(element => {
							element.FullPresentation = element.TypeBoxPresentationText + ' | ' + element.TypePresentationPackingName
						})
						console.log('sssss ', this.itemsPresentation);
					}
				});
			},

			save(item)
			{
				if(item.TbrQuantityPrint == "" || item.TbrQuantityPrint == 0)
				{
					this.$fun.alert("Ingrese cantidad a imprimir", "warning");
					return;
				}
				item.TbrStatus = 1;
				item.SecStatus = 1;
				item.TypeArea = this.TypeArea;
				item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();
				item.save();
				console.log('a guardar ', item);
				this.baler.NtpFullName = '';

			},

			generateCode()
			{
				
			}
		},

		created () {
			this.getPresentations();
		},
	}
</script>

<style lang="scss" scoped>

</style>