import Service from '../Service';

const resource = "tagpresentationsupervisor/"

export default {

    save(tps, requestID) {
        return Service.post(resource + 'save', tps, {
            params: { requestID }
        });
    },

    list(TypeArea, requestID) {
        return Service.post(resource + 'list', {}, {
            params: { TypeArea, requestID }
        });
    },

    listLot(obj, requestID) {
        return Service.post(resource + 'listLot', obj, {
            params: {requestID: requestID},
        });
    },

}