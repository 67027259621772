import Service from "../Service";

const resource = "tagbaler/"

export default {

    save(obj, requestID) {

        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    pagination(obj, requestID) {

        return Service.post(resource + "pagination", obj, {
            params: { requestID: requestID },
        });
    },

    search(obj, requestID) {

        return Service.post(resource + "search", obj, {
            params: { requestID: requestID },
        });
    },

};